// BenefitGrid.jsx
import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { motion } from "framer-motion";

const CardGrid = ({ benefits }) => {
  return (
    <Grid container spacing={4} justifyContent="center">
      {benefits.map((benefit, index) => (
        <Grid item xs={12} md={6} lg={3} key={index}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <Paper
              elevation={8}
              sx={{
                p: 4,
                textAlign: "center",
                borderRadius: "8px",
                minHeight: { xs: "230px", md: "250px", lg: "250px" },
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              {benefit.icon}
              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                {benefit.title}
              </Typography>
              <Typography variant="body2">{benefit.description}</Typography>
            </Paper>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardGrid;
