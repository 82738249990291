// CallToAction.jsx
import React from "react";
import { Box, Grid, Button } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const CallToAction = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(t("links.whatsappLink"), "_blank");
  };

  const handleCalendlyRedirect = () => {
    window.open(t("links.calendlyLink"), "_blank");
  };

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      textAlign="center"
      color="white"
      p={{ xs: 4, md: 6, lg: 2 }} // Ajusta el padding según sea necesario
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Button
            component={motion.a}
            target="_blank"
            variant="contained"
            color="primary"
            size="large"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            endIcon={<WhatsAppIcon />}
            onClick={handleClick}
            fullWidth
            sx={{ mb: 2 }} // Agrega margen inferior si deseas espacio entre los botones
          >
            {t("callToAction.contactButton")}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Button
            component={motion.a}
            variant="contained"
            color="primary"
            size="large"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleCalendlyRedirect}
            fullWidth
          >
            {t("callToAction.meetButton")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallToAction;
