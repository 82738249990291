import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AnimatedButton from "../../components/AnimatedButton";
import NavbarMobile from "./components/NavbarMobile";
import { motion } from "framer-motion";
import useScrollToSection from "../../hooks/useScrollToSection";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const scrollToSection = useScrollToSection();
  const { t } = useTranslation();

  const buttons = [
    { label: t("navbar.home"), section: "inicio", delay: 0.2 },
    { label: t("navbar.services"), section: "ofrecemos", delay: 0.2 },
    { label: t("navbar.benefits"), section: "servicios", delay: 0.4 },
    { label: t("navbar.tecnologies"), section: "tec", delay: 0.4 },
    { label: t("navbar.faqs"), section: "faqs", delay: 0.6 },
    {
      label: t("navbar.contact"),
      section: "contacto",
      delay: 0.8,
      customStyles: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
  ];

  return (
    <AppBar position="fixed" color="primary">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: "auto",
            }}
          >
            <img
              src="/logo.png"
              alt="Real State Hub"
              style={{ height: "50px", marginLeft: "10%" }}
            />
            {/* {t("title")} */}
          </Typography>
        </motion.div>

        {isMobile ? (
          <NavbarMobile />
        ) : (
          <Box display="flex" alignItems="center">
            {buttons.map((button, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: button.delay }}
              >
                <AnimatedButton
                  color="inherit"
                  sx={{ mr: 2, ...button.customStyles }}
                  onClick={() => scrollToSection(button.section)}
                >
                  {button.label}
                </AnimatedButton>
              </motion.div>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
