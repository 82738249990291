import React from "react";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import { Typography, Box, Container, useMediaQuery } from "@mui/material";
import AnimatedButton from "../components/AnimatedButton";
import useScrollToSection from "../hooks/useScrollToSection";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const scrollToSection = useScrollToSection();
  const { t } = useTranslation();

  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.section
      initial="hidden"
      animate="visible"
      variants={sectionVariants}
      transition={{ duration: 1.2, ease: "easeOut" }}
      style={{
        position: "relative",
        minHeight: "80vh",
        backgroundImage: 'url("/banner.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "2rem",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))",
          zIndex: 1,
        }}
      />
      <Container sx={{ position: "relative", zIndex: 2 }}>
        <Box
          maxWidth="500px"
          color="#fff"
          textAlign={{ xs: "left", md: "left", lg: "left" }}
          spacing={{ xs: 2, md: 4 }}
          pt={{ xs: 4, md: 4, lg: 6 }}
        >
          <Typography
            variant={isSmallScreen ? "h3" : "h1"}
            gutterBottom
            fontWeight="bold"
            pt={isSmallScreen ? 3 : 0}
          >
            {t("banner.title")}
          </Typography>
          <Typography
            variant={isSmallScreen ? "h7" : "h5"}
            paragraph
            fontWeight="medium"
          >
            {t("banner.description")}
          </Typography>

          <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            gap={isSmallScreen ? "30px" : "40px"}
            alignContent="center"
            width="100%"
            pt={3}
          >
            <AnimatedButton
              sx={{
                color: "black",
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                },
                padding: "10px 20px",
                fontWeight: 600,
                width: isSmallScreen ? "100%" : "350px",
              }}
              onClick={() => scrollToSection("contacto")}
            >
              {t("banner.contactButton")}
            </AnimatedButton>
            <AnimatedButton
              sx={{
                backgroundColor: isSmallScreen
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: isSmallScreen
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                },
                color: isSmallScreen ? "white" : "black",
                padding: "10px 20px",
                fontWeight: 600,
                width: isSmallScreen ? "100%" : "350px",
              }}
              onClick={() => scrollToSection("ofrecemos")}
            >
              {t("banner.learnMoreButton")}
            </AnimatedButton>
          </Box>
        </Box>
      </Container>
    </motion.section>
  );
};

export default Banner;
