import React from "react";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";

const AnimatedButton = ({ children, ...props }) => (
  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
    <Button {...props}>{children}</Button>
  </motion.div>
);

export default AnimatedButton;
