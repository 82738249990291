// FAQs.jsx
import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const FAQs = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const faqs = [
    {
      question: t("faqs.faq1.question"),
      answer: t("faqs.faq1.answer"),
    },
    {
      question: t("faqs.faq2.question"),
      answer: t("faqs.faq2.answer"),
    },
    {
      question: t("faqs.faq3.question"),
      answer: t("faqs.faq3.answer"),
    },
    {
      question: t("faqs.faq4.question"),
      answer: t("faqs.faq4.answer"),
    },
    {
      question: t("faqs.faq5.question"),
      answer: t("faqs.faq5.answer"),
    },

    {
      question: t("faqs.faq6.question"),
      answer: t("faqs.faq6.answer"),
    },
    {
      question: t("faqs.faq7.question"),
      answer: t("faqs.faq7.answer"),
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      id="faqs"
      py={{ xs: 8, md: 12 }}
      px={{ xs: 2, md: 8 }}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Typography variant="h3" fontWeight="bold" align="center" mb={4}>
        {t("faqs.title")}
      </Typography>
      {faqs.map((faq, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.2 }}
        >
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.text.primary,
              mb: 2,
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />
              }
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
              sx={{
                "& .MuiAccordionSummary-content": {
                  alignItems: "center",
                },
              }}
            >
              <HelpOutlineIcon
                sx={{ color: theme.palette.primary.main, mr: 2 }}
              />
              <Typography variant="h6" fontWeight="bold">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              <Typography variant="body1">{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        </motion.div>
      ))}
    </Box>
  );
};

export default FAQs;
