import React, { useState, useEffect } from "react";
import { Fab, Box, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

const WhatsAppButton = () => {
  const [hover, setHover] = useState(false);
  const [showInitialMessage, setShowInitialMessage] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInitialMessage(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {
    window.open(t("links.whatsappLink"), "_blank");
  };

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      position="fixed"
      bottom={16}
      right={16}
      zIndex={1000}
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
    >
      <AnimatePresence>
        {(hover || showInitialMessage) && (
          <Box
            // component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            mb={1}
            bgcolor="white"
            borderRadius={1}
            p={1}
            boxShadow={1}
            maxWidth={200}
          >
            <Typography variant="body2" color="textSecondary">
              {t("whatsappIcon.labelButton")}
            </Typography>
          </Box>
        )}
      </AnimatePresence>
      <Fab
        color="success"
        aria-label="whatsapp"
        onClick={handleClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <WhatsAppIcon />
      </Fab>
    </Box>
  );
};

export default WhatsAppButton;
