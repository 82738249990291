// Benefits.jsx
import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CardGrid from "../components/CardGrid";
import { useTranslation } from "react-i18next";
import {
  Speed as SpeedIcon,
  Devices as DevicesIcon,
  Security as SecurityIcon,
  Expand as ExpandIcon,
} from "@mui/icons-material";

const Benefits = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const beneficiosData = [
    {
      title: t("benefits.benefit1.title"),
      description: t("benefits.benefit1.description"),
      icon: <ExpandIcon fontSize="large" />,
    },
    {
      title: t("benefits.benefit2.title"),
      description: t("benefits.benefit2.description"),
      icon: <SpeedIcon fontSize="large" />,
    },
    {
      title: t("benefits.benefit3.title"),
      description: t("benefits.benefit3.description"),
      icon: <DevicesIcon fontSize="large" />,
    },
    {
      title: t("benefits.benefit4.title"),
      description: t("benefits.benefit4.description"),
      icon: <SecurityIcon fontSize="large" />,
    },
  ];
  return (
    <Box id="benefits" py={{ xs: 8, md: 12, lg: 16 }} px={{ xs: 2, md: 8 }}>
      <Typography
        variant={isSmallScreen ? "h4" : "h3"}
        fontWeight="bold"
        align="center"
        mb={{ xs: 3, md: 4, lg: 5 }}
      >
        {t("benefits.title")}
      </Typography>

      <CardGrid benefits={beneficiosData} />
    </Box>
  );
};

export default Benefits;
