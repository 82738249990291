import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onSubmit = (data) => {
    fetch(t("links.formsContact"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          setSnackbarMessage(t("contact.formValidation.success"));
          setOpenSnackbar(true);
          reset();
          window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
        } else {
          setSnackbarMessage(t("contact.formValidation.error"));
          setOpenSnackbar(true);
        }
      })
      .catch(() => {
        setSnackbarMessage(t("contact.formValidation.error"));
        setOpenSnackbar(true);
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCalendlyRedirect = () => {
    window.open(t("links.calendlyLink"), "_blank");
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "4xl", mx: "auto", py: 12 }}>
      <Grid container spacing={{ xs: 6, md: 2 }} justifyContent="center">
        <Grid item xs={12} md={4} container justifyContent="center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            style={{ width: "90%", maxWidth: "600px" }}
          >
            <Typography
              variant={isSmallScreen ? "h5" : "h4"}
              gutterBottom
              fontWeight="bold"
              textAlign={{ xs: "center", md: "left" }}
            >
              {t("contact.formContact.title")}
            </Typography>
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              mb={4}
              textAlign={isSmallScreen ? "center" : "left"}
            >
              {t("contact.formContact.description")}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t(
                        "contact.formValidation.validations.name.null"
                      ),
                      minLength: {
                        value: 5,
                        message: t(
                          "contact.formValidation.validations.name.short"
                        ),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={
                          <span>
                            {t("contact.formContact.labelName")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t(
                        "contact.formValidation.validations.email.null"
                      ),
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t(
                          "contact.formValidation.validations.email.invalid"
                        ),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={
                          <span>
                            {t("contact.formContact.labelEmail")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        type="email"
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t("contact.formContact.labelPhone")}
                        type="tel"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="message"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t(
                        "contact.formValidation.validations.message.null"
                      ),
                      minLength: {
                        value: 15,
                        message: t(
                          "contact.formValidation.validations.message.short"
                        ),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={
                          <span>
                            {t("contact.formContact.labelMessage")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        multiline
                        rows={5}
                        error={!!errors.message}
                        helperText={
                          errors.message ? errors.message.message : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      width: !isSmallScreen ? "50%" : "100%",
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  >
                    {t("contact.formContact.labelButton")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </motion.div>
        </Grid>
        <Grid item xs={12} md={4} container justifyContent="center">
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            style={{ width: "90%", maxWidth: "500px" }}
          >
            <Typography
              variant={isSmallScreen ? "h5" : "h4"}
              gutterBottom
              fontWeight="bold"
              textAlign={{ xs: "center", md: "left" }}
            >
              {t("contact.meetContact.title")}
            </Typography>
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              mb={4}
              textAlign={isSmallScreen ? "center" : "left"}
            >
              {t("contact.meetContact.sub-title")}
            </Typography>
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              mb={4}
              textAlign={isSmallScreen ? "center" : "left"}
            >
              {t("contact.meetContact.description")}
            </Typography>
            <Button
              onClick={handleCalendlyRedirect}
              variant="contained"
              sx={{
                width: !isSmallScreen ? "50%" : "100%",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
            >
              {t("contact.meetContact.labelButton")}
            </Button>
          </motion.div>
        </Grid>
      </Grid>

      {/* Snackbar for Submission Feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Contact;
