// src/App.js
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";

import WhatsAppButton from "./components/WhatsappIcon";

import Navbar from "./Layout/Navbar/Navbar";
import Footer from "./Layout/Footer";
import CallToAction from "./Layout/CallToAction";

import Banner from "./pages/Banner";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Benefits from "./pages/Benefits";
import FAQs from "./pages/FAQs";
import Technologies from "./pages/Tecnologies";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <div id="inicio">
        <Banner />
      </div>
      <div id="ofrecemos">
        <Services />
      </div>
      <CallToAction />
      <div id="servicios">
        <Benefits />
      </div>
      <div id="tec">
        <Technologies />
      </div>
      <div id="contacto">
        <Contact />
      </div>
      <div id="faqs">
        <FAQs />
      </div>
      <WhatsAppButton />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
