import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import { FaReact, FaNodeJs, FaAws, FaDocker, FaDatabase } from "react-icons/fa";
import { SiRedux, SiNextdotjs, SiMongodb } from "react-icons/si";
import { useTranslation } from "react-i18next";

const Technologies = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const technologies = [
    { name: t("tecnologies.react"), icon: <FaReact size="3rem" /> },
    { name: t("tecnologies.redux"), icon: <SiRedux size="3rem" /> },
    { name: t("tecnologies.next"), icon: <SiNextdotjs size="3rem" /> },
    { name: t("tecnologies.node"), icon: <FaNodeJs size="3rem" /> },
    { name: t("tecnologies.sql"), icon: <FaDatabase size="3rem" /> },
    { name: t("tecnologies.mongo"), icon: <SiMongodb size="3rem" /> },
    { name: t("tecnologies.aws"), icon: <FaAws size="3rem" /> },
    { name: t("tecnologies.docker"), icon: <FaDocker size="3rem" /> },
  ];

  return (
    <Box
      id="technologies"
      sx={{
        backgroundColor: "#000",
        color: "#fff",
        py: 8,
        textAlign: "center",
        width: "100%",
      }}
    >
      <Typography
        variant={isSmallScreen ? "h5" : "h3"}
        fontWeight="bold"
        mb={6}
      >
        {t("tecnologies.title")}
      </Typography>

      <Grid
        container
        spacing={isSmallScreen ? 4 : 8}
        justifyContent="center"
        alignItems="center"
      >
        {technologies.map((tech, index) => (
          <Grid item key={index}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              whileHover={{ scale: 1.2, color: theme.palette.secondary.main }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {tech.icon}
            </motion.div>
            <Typography variant="body1" mt={2}>
              {tech.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Technologies;
