// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0F0F0F",
    },
    secondary: {
      main: "#FFFFFF",
    },
    callToAction: {
      default: "#0F0F0F",
      secondary: "#FFFFFF",
    },
    navbar: {
      default: "#0F0F0F",
      primary: "#FFFFFF",
    },
    footer: {
      default: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

export default theme;
