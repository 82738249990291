// QueOfrecemos.jsx
import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CardGrid from "../components/CardGrid";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@mui/icons-material/Settings";
import UpdateIcon from "@mui/icons-material/Update";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import {
  LaptopMac as LaptopMacIcon,
  TrendingUp as TrendingUpIcon,
  ShowChart as ShowChartIcon,
  Storage as StorageIcon,
} from "@mui/icons-material";

const QueOfrecemos = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const queOfrecemosServices = [
    {
      title: t("services.service1.title"),
      description: t("services.service1.description"),
      icon: <LaptopMacIcon fontSize="large" />,
    },
    {
      title: t("services.service2.title"),
      description: t("services.service2.description"),
      icon: <TrendingUpIcon fontSize="large" />,
    },
    {
      title: t("services.service3.title"),
      description: t("services.service3.description"),
      icon: <ShowChartIcon fontSize="large" />,
    },
    {
      title: t("services.service4.title"),
      description: t("services.service4.description"),
      icon: <StorageIcon fontSize="large" />,
    },
    {
      title: t("services.service5.title"),
      description: t("services.service5.description"),
      icon: <SettingsIcon fontSize="large" />,
    },
    {
      title: t("services.service6.title"),
      description: t("services.service6.description"),
      icon: <UpdateIcon fontSize="large" />,
    },
    {
      title: t("services.service7.title"),
      description: t("services.service7.description"),
      icon: <ThumbUpIcon fontSize="large" />,
    },
    {
      title: t("services.service8.title"),
      description: t("services.service8.description"),
      icon: <AlarmOnIcon fontSize="large" />,
    },
  ];

  return (
    <Box
      id="que-ofrecemos"
      py={{ xs: 8, md: 12, lg: 16 }}
      px={{ xs: 2, md: 8 }}
    >
      <Typography
        variant={isSmallScreen ? "h4" : "h3"}
        fontWeight="bold"
        align="center"
        mb={{ xs: 3, md: 4, lg: 5 }}
      >
        {t("services.title")}
      </Typography>

      <CardGrid benefits={queOfrecemosServices} />
    </Box>
  );
};

export default QueOfrecemos;
