import React, { useState } from "react";
import { Box, ListItemButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import HelpIcon from "@mui/icons-material/Help";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import AppsIcon from "@mui/icons-material/Apps";
import { motion } from "framer-motion";
import useScrollToSection from "../../../hooks/useScrollToSection";
import { useTranslation } from "react-i18next";

const NavbarMobile = () => {
  const scrollToSection = useScrollToSection();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
    console.log(newOpen);
  };

  const menuItems = [
    { text: t("navbar.home"), section: "inicio", icon: <HomeIcon /> },
    { text: t("navbar.services"), section: "ofrecemos", icon: <ArticleIcon /> },
    { text: t("navbar.benefits"), section: "servicios", icon: <LoyaltyIcon /> },
    { text: t("navbar.tecnologies"), section: "tec", icon: <AppsIcon /> },
    { text: t("navbar.faqs"), section: "faqs", icon: <HelpIcon /> },
    {
      text: t("navbar.contact"),
      section: "contacto",
      icon: <PermContactCalendarIcon />,
    },
  ];

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "55%",
          },
        }}
      >
        <List>
          {menuItems.map((item, index) => (
            <motion.div
              key={item.text}
              initial={{ x: 100 }}
              animate={{ x: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <ListItemButton
                onClick={() => {
                  toggleDrawer(false)();
                  scrollToSection(item.section);
                }}
              >
                <Box gap={1} display="flex" justifyContent="flex-start">
                  <ListItemIcon sx={{ minWidth: 35 }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </Box>
              </ListItemButton>
            </motion.div>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default NavbarMobile;
