// src/hooks/useScrollToSection.js
import { useCallback } from "react";

const useScrollToSection = () => {
  const scrollToSection = useCallback((id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return scrollToSection;
};

export default useScrollToSection;
