// src/components/Footer.js
import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
  Box,
  useMediaQuery,
} from "@mui/material";
import { Instagram, LinkedIn, WhatsApp } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import useScrollToSection from "../hooks/useScrollToSection";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const scrollToSection = useScrollToSection();

  const handleSubscribe = async () => {
    if (!email) {
      setError(t("forms.error.null"));
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError(t("forms.error.invalid"));
      return;
    }

    setError("");

    try {
      const response = await fetch(t("links.formsNewsletter"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });

      if (response.ok) {
        setSnackbarMessage(t("forms.success"));
        setOpenSnackBar(true);
        setEmail("");
      } else {
        setError(t("forms.error.submit"));
      }
    } catch (error) {
      setError(t("forms.error.submit"));
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
  };

  const sections = [
    { id: "inicio", label: t("navbar.home") },
    { id: "ofrecemos", label: t("navbar.services") },
    { id: "servicios", label: t("navbar.benefits") },
    { id: "tec", label: t("navbar.tecnologies") },
    { id: "faqs", label: t("navbar.faqs") },
    { id: "contacto", label: t("navbar.contact") },
  ];

  return (
    <footer
      style={{
        backgroundColor: theme.palette.secondary.main,
        padding: "0 10%",
        width: "100%",
      }}
    >
      <Grid container spacing={{ xs: 4, md: 6, lg: 6 }} justifyContent="center">
        {/* Logo and Company Name */}
        <Grid item xs={12} sm={6} md={3}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <img
                src="/logofooter.png"
                alt="Company Logo"
                style={{ height: "6vh", marginBottom: "10px" }}
              />
              <Typography variant="h6" gutterBottom>
                {/* {t("title")} */}
              </Typography>
            </Box>
          </motion.div>
        </Grid>

        {/* Contact Information */}
        <Grid item xs={12} sm={6} md={3}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <Typography variant="h6" gutterBottom>
              {t("footer.contactData.title")}
            </Typography>
            <Typography variant="body2">
              {t("footer.contactData.phone")}
            </Typography>
            <Typography variant="body2">
              {t("footer.contactData.email")}
            </Typography>
          </motion.div>
        </Grid>

        {/* Quick Links */}
        <Grid item xs={12} sm={6} md={3}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <Typography variant="h6" gutterBottom>
              {t("footer.quickLinks.title")}
            </Typography>
            {sections.map((section) => (
              <Typography
                key={section.id}
                variant="body2"
                sx={{
                  cursor: "pointer",
                  mb: 1,
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={() => scrollToSection(section.id)}
              >
                {section.label}
              </Typography>
            ))}
          </motion.div>
        </Grid>

        {/* Newsletter Signup */}
        <Grid item xs={12} sm={6} md={3}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <Typography variant="h6" gutterBottom>
              {t("footer.newsletter.title")}
            </Typography>
            <TextField
              label={t("footer.newsletter.labelEmail")}
              variant="outlined"
              size="small"
              fullWidth
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              helperText={error}
              error={!!error}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubscribe}
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: isSmallScreen ? "100%" : "65%",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
            >
              {t("footer.newsletter.subscribeButton")}
            </Button>
          </motion.div>
        </Grid>
      </Grid>

      {/* CopyRight */}
      <Typography variant="body2" align="center" sx={{ mt: 4 }}>
        © {new Date().getFullYear()} {t("footer.copyRight.companyName")}
      </Typography>
      <Box textAlign="center" marginTop="10px" marginBottom={4}>
        <Tooltip
          title={t("footer.socialMedia.instagram.tooltip")}
          arrow
          placement="top"
        >
          <IconButton
            href={t("links.instagramLink")}
            target="_blank"
            color="inherit"
            sx={{
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          >
            <Instagram />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={t("footer.socialMedia.linkedin.tooltip")}
          arrow
          placement="top"
        >
          <IconButton
            href={t("links.linkedinLink")}
            target="_blank"
            color="inherit"
            sx={{
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          >
            <LinkedIn />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={t("footer.socialMedia.whatsapp.tooltip")}
          arrow
          placement="top"
        >
          <IconButton
            href={t("links.whatsappLink")}
            target="_blank"
            color="inherit"
            sx={{
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          >
            <WhatsApp />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Snackbar for Subscription Success */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </footer>
  );
};

export default Footer;
